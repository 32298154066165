.pagination {
  margin-top: 1rem;
  padding-right: 1rem;
}

.pagination li a {
  color: var(--color-secondary);
  font-weight: 600;
}

.pagination li a:hover {
  color: var(--color-secondary);
  background-color: var(--color-secondary-light);
}

.page-item.active .page-link {
  background-color: var(--color-secondary);
}

.pagination .page-link {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.page-link i {
  font-size: 20px;
  /* top: 10px; */
}

.pagination .count-number {
  height: 40px;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
