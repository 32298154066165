.file_uploader {
  height: 100px;
  background-color: var(--color-secondary-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: var(--color-secondary);
  border: 3px dashed var(--gray-light);
  width: 100%;
}

.file_uploader:hover {
  border: 5px dashed var(--gray-light);
}

.file_list {
  display: flex;
  align-items: center;
}

.file_list span {
  flex: 1;
}

.file_list .icon-con {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.file_list .icon-con i {
  font-size: 1.3rem;
}

.download-button {
  cursor: pointer;
}