.input-label {
  display: flex;
  align-items: center;
}

.input-label label {
  flex: 1;
}

.input-content {
  display: flex;
  align-items: center;
  position: relative;
}

.input-content .countries {
  margin-right: 15px;
}

.input-content textarea {
  min-height: 100px;
}

.input-content input,
.input-content textarea {
  flex: 1;
}

.form-group {
  margin-bottom: 15px;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid var(--color-secondary);
}

.input-content .right-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1rem;
}

.input-content .right-box a {
  text-decoration: 0;
  font-weight: 500;
}

label {
  margin-bottom: 8px;
}

.required {
  color: var(--danger);
}

.no-border-bottom > .form-group {
  margin-bottom: 0 !important;
}

.input-content .iconRight {
  position: absolute;
  right: 0;
  width: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selector__value-container--is-multi {
  max-height: 2.5rem;
  overflow-y: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.selector__value-container--is-multi::-webkit-scrollbar {
  display: none;
}

.form-control {
  font-weight: 300;
}

.hidden {
  display: none;
}
