.spin-container {
  min-width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-container span {
  width: 5rem;
  height: 5rem;
}
