.sidebar {
  width: 210px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition: width ease 200ms;
  background-color: var(--white);
  box-shadow: 0px 2.76726px 2.21381px rgba(51, 51, 51, 0.04);
  padding-top: 1rem;
}

.sidebar.hide {
  width: 0;
  visibility: hidden;
}
/* 
.sidebar .header {
  min-height: 3.58rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
} */

.sidebar .header .toggle {
  opacity: 0.5;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}

.sidebar .header .toggle i {
  font-size: 1.3rem;
}

.sidebar .header .logo {
  padding: 0 0.5rem;
}

.sidebar .header .name {
  flex: 1;
  font-weight: 700;
  color: var(--color-secondary);
  font-size: 16px;
  margin-top: 2px;
}

.sidebar .middle {
  flex: 1;
  overflow-y: auto;
}

.sidebar ul {
  margin: 0;
  padding: 0;
}

.sidebar ul li {
  list-style: none;
}

.sidebar ul li h1 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  height: 3rem;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  color: #a5a4a4;
  padding: 0 1rem;
}

.sidebar ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color);
  opacity: 0.7;
  margin: 0 1rem;
  border-radius: 0.3rem;
  min-height: 2.5rem;
  padding: 0 0.3rem;
}

.sidebar ul li a i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.sidebar ul li a span {
  margin: 0 0.5rem;
  flex: 1;
  font-weight: 600;
}

.sidebar ul li a:hover,
.sidebar ul li a.active {
  opacity: 1;
  color: var(--color-secondary);
  font-weight: 500;
  background-color: var(--color-secondary-light);
}

.sidebar .footer {
  border-top: 1px solid var(--gray-light);
  /* padding: 0.5rem 0; */
}

.sidebar .footer li a:hover {
  background-color: var(--white);
}

@media screen and (max-width: 600px) {
  .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 999;
    left: -200px;
    transition: 200ms;
  }

  .sidebar.show {
    left: 0;
  }

  .sidebar .header .toggle {
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar.show::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: 200px;
  }
}
