.bg-light {
  --bs-bg-opacity: 1;
  background-color: var(--color-primary) !important;
  z-index: 100;
  padding-top: 0;
  padding-bottom: 0;
  height: 4rem;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 5px;
  position: relative;
}

.nav-item .nv-i {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  border-radius: 20px;
  cursor: pointer;
  color: var(--color);
  text-decoration: none;
}

.nav-item .nv-i:hover {
  background-color: var(--gray-light);
}

.nv-i i {
  font-size: 18px;
}

.nv-notification {
  position: absolute;
  top: 0;
  right: -4px;
  top: -10px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--danger);
  border-radius: 20px;
  border: 2px solid var(--color-primary);
  z-index: 23232323;
}

.nv-notification span {
  font-size: 12px;
  color: var(--color-primary);
  font-weight: 700;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand h1 {
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.toggleBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleBtn i {
  font-size: 22px;
  opacity: 0.5;
}

.navbar-items ul {
  display: flex;
  align-items: center;
}

.notification-list {
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  min-width: 300px;
  min-height: 100px;
}

.notification-list.empty {
  background-color: var(--gray-light-2x);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.notification-list p {
  font-size: 12px;
}

.empty_not_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty_not_icon i {
  font-size: 2rem;
}
.notification-body h6 {
  font-size: medium;
  font-weight: 600;
  color: black;
}
.notification-body p {
  font-size: medium;
  color: gray;
}
.notification {
  padding: 10px;
  border-bottom: 1px solid var(--gray);
}
.title-wrap {
  padding: 10px;
}
.title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.dropdown-item:active {
  background-color: var(--color-primary);
  color: var(--color);
}

@media screen and (max-width: 600px) {
  .bg-light .container-fluid {
    display: flex;
    align-items: center;
  }

  .navbar-brand {
    flex: 1;
  }

  .navbar .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
  }
}
