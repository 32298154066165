.modal-header {
  background-color: var(--color-secondary-light);
  border-bottom: 0;
}

.modal-footer {
  background-color: var(--color-secondary-light);
  padding-right: 1rem !important;
}

.modal_title {
  font-size: 16px;
  color: var(--color-secondary);
}

.delete-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}

.delete-body i {
  font-size: 2rem;
  color: var(--danger);

}
