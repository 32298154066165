:root {
  --color-primary: #f4f5fe;
  --color-secondary: #1E3466;
  --color-secondary-light: #e6e9ef;
  --danger: red;
  --gray-light-2x: #f2f2f2;
  --gray-light: #dddddd;
  --gray: #999;
  --box-shadow: 0 1px 3px rgba(216, 216, 216, 0.37),
    0 1px 2px rgba(230, 230, 230, 0.486);
  --z-fixed: 100;
  --white: #fff;
  --color: #21201E;
}

.App {
  height: 100%;
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: var(--color-secondary);
  text-decoration: none;
}

a:focus,
a:hover {
  color: var(--color-secondary);
}

a.link-disabled {
  opacity: 0.5;
}

h1 {
  margin-bottom: 0;
  font-size: 22px;
}

h4 {
  margin-bottom: 0;
  font-size: 19px;
}

.text-disabled {
  opacity: 0.5;
}

/* home layout style */
.app-home-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.app-main-container {
  flex: 1;
  /* height: 100vh; */
  overflow-y: hidden;
}

.app-main-content {
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 100px;
  width: 100%;
  overflow-y: auto;
  /* margin: 1.5rem 0; */
  margin-bottom: 1.5rem;
}

.modal-body .card {
  border: 1px solid var(--color-secondary);
}

.card {
  border: 1px solid transparent;
}

.card-border {
  border: 1px solid var(--gray-light);
}

.card-header {
  background-color: white;
  border-bottom: 0;
  min-height: 3rem;
  display: flex;
  align-items: center;
}

.modal-body .card-header {
  background-color: white;
  border-bottom: 0;
  min-height: 3rem;
  display: flex;
  align-items: center;
  margin-top: -1.6rem;
  background: transparent;
}

.card-header h1 {
  font-size: 16px;
  color: var(--color-secondary);
  background-color: white;
  padding: 0 0.5rem;
}

.fixed-zindex {
  z-index: 200;
}

.card-header h1 {
  font-size: 16px;
  color: var(--color);
  margin-bottom: 0;
}

/* scroll bar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  /* background-color: var(--color-secondary-light);
  outline: 1px solid var(--color-secondary-light); */
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-light);
  outline: 1px solid var(--gray-light);
}

.table {
  margin-bottom: 0;
}

thead {
  /* background-color: var(--color-secondary-light); */
  border-bottom: 0 !important;
}

th {
  cursor: pointer;
  font-weight: 500;
}

.card-footer {
  background-color: var(--white);
}

.no-border {
  border-top: 0;
}

.modal-footer {
  background-color: var(--white);
}

.dropdown-menu {
  padding: 0;
}

.table {
  table-layout: auto;
  /* width: 100%; */
}

.table-hover tbody tr:hover {
  cursor: pointer;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.table tfoot > tr > td.data {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px !important;
  overflow: hidden;
}

.table .btn {
  margin: 0;
}

.table .btn:active,
.table .btn:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.table-custom-card {
  border-collapse: separate;
  border-spacing: 0 0.2em;
}

.table-custom-card tr {
  border: 1px solid transparent;
}

.table-custom-card tbody tr {
  height: 60px;
  margin-bottom: 1rem !important;
  border: 2px solid var(--gray-light);
}

.table-custom-card tbody tr td {
  flex: 1;
}

.table-custom-card thead {
  background-color: var(--gray-light-2x);
}

td img {
  width: 40px;
  border-radius: 0.5rem;
}

.accordion-button:not(.collapsed) {
  color: var(--color-secondary);
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.text-link {
  color: var(--color-secondary);
}
