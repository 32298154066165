@font-face {
  font-family: ceraRoundProRegular;
  src: url("./app/assets/fonts/ceraRoundProRegular.otf") format("opentype");
}

@font-face {
  font-family: ceraRoundProBold;
  font-weight: bold;
  src: url("./app/assets/fonts/ceraRoundProBold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: ceraRoundProRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #010a05;
  overflow-y: hidden;
  font-size: 14px;
  width: 100%;
}
