.nav-tabs {
  z-index: 999999;
}
.tab {
  border-bottom: 1px solid var(--gray-light);
  margin-top: 0;
}

.tab .nav-item button,
.tab .nav-item {
  font-weight: 700;
  color: var(--color);
}

.nav-tabs .nav-link {
  opacity: 0.6;
  border: 0;
  border-radius: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-radius: 0;
  background-color: var(--color-primary);
}

.tab-pane .card-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.nav-tabs .nav-link.active {
  color: var(--color-secondary);
  opacity: 1;
  border-bottom: 2px solid var(--color-secondary);
}
