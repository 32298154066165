.table-card-header {
  background-color: var(--gray-light);
}

.sort-btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.3;
}

.sort-btn i {
  font-size: 19px;
  padding: 0;
  margin: 0;
}

.sort-btn:hover,
.sort-btn:active {
  opacity: 1;
  color: var(--color-secondary);
}

.badge-not-submitted {
  color: var(--color-secondary);
  background-color: var(--color-secondary-light);
  width:100%;
  padding: 3px 5px;
  border-radius: 2px;
  text-align: center;
}

.badge-success {
  color: green;
  background-color: lightgreen;
  width:100%;
  padding: 3px 5px;
  border-radius: 2px;
  text-align: center;
}

.badge-error {
  color: rgb(238, 127, 9);
  background-color: rgb(252, 218, 182);
  width:100%;
  padding: 3px 5px;
  border-radius: 2px;
  text-align: center;
}
