/* button styles */
.btn {
  margin: 8px 0;
  border: 1px solid transparent;
  white-space: nowrap;
}

.btn-primary {
  background-color: var(--color-secondary) !important;
  color: var(--color-primary);
  border: 1px solid var(--color-secondary);
  box-shadow: 0 1px 3px rgba(3, 187, 3, 0.12), 0 1px 2px rgba(72, 173, 25, 0.24);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  border-color: var(--color-secondary);
}

.btn-delete {
  color: var(--color-primary);
  background-color: var(--danger);
  box-shadow: none;
  border: none;
}

.btn-delete:hover,
.btn-delete:active,
.btn-delete:focus {
  border: none;
}

.btn-secondary {
  background-color: var(--color-secondary-light) !important;
  color: var(--color-secondary);
  box-shadow: 0 1px 3px rgba(3, 187, 3, 0.12), 0 1px 2px rgba(72, 173, 25, 0.24);
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  color: var(--color-secondary) !important;
  outline: none;
  box-shadow: none;
}

.btn-default {
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  color: var(--color);
}

.btn-gray {
  background-color: var(--gray-light);
}

.btn-transparent {
  background-color: transparent !important;
  color: var(--color);
}

.btn-border {
  border: 2px solid var(--color-secondary);
}

.btn.circle {
  border-radius: 30px;
}

.link-icon i {
  font-size: 22px;
  min-height: 28px;
  margin-top: 5px;
}

.link-icon {
  box-shadow: none;
  border: 0;
  outline: none;
  padding: 0;
  width: 28px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.link-icon:hover,
.link-icon:active,
.link-icon:focus {
  box-shadow: none;
  border: 0;
  outline: none;
}

.btn-sm {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-link {
  color: var(--color-secondary);
  text-decoration: none;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  outline: none;
  box-shadow: none;
  color: var(--color-secondary);
}

.btn:disabled,
.btn[disabled] {
  background-color: var(--gray-light) !important;
  border: 1px solid var(--gray-light) !important;
  color: var(--gray);
}

.btn i {
  font-size: 15px;
  position: relative;
  top: 0.13rem;
}
