.form-container .card-header {
  background-color: var(--color-primary);
}

.form-container {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: var(--color-secondary-light);
  justify-content: center;
  overflow-y: auto;
}

.form-container .card {
  justify-content: center;
  box-shadow: var(--box-shadow);
  border: 0;
}

.form-container .card-header {
  background-color: var(--color-primary);
}

.form-desc-container {
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-desc-container .overlay {
  background: linear-gradient(rgba( 5,63,228, 0.7), rgba(5,80,228, 0.7));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.form-header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  gap: 1rem;
}

.form-desc-container .overlay h1 {
  color: var(--color-primary);
  font-weight: 700;
}

.form-desc-container .overlay p {
  color: var(--color-primary);
  opacity: 0.8;
}

.form-desc-container .overlay p a {
  color: var(--color-primary);
  font-weight: 700;
}

.user-session-out {
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
}

.user-session-out .out-user-info {
  flex: 1;
}

.user-session-out .out-user-info p {
  margin-bottom: 0;
}

.activate-body {
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.login-container {
  /* padding: 1rem; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

@media screen and (max-width: 760px) {
  .form-container {
    display: block;
  }
}
